module.exports = {

    head: {
        home: '首页',
        czzzjh: 'CZ增值计划',
        mint: 'MINT',
        connect: '连接'
    },

    index: {
        wodezhanghao: '我的帳號',
        fenxiang: '分享',
        tuijianzhe: '推薦者',
        zhituiyeji: '直推業績',
        jiantuiyeji: '間推業績',
        lingyang: '領養',
        lingyangjine: '領養金額',
        wodelingyangjine: '我的領養金額',
        jiangli: '獎勵',
        kelingquzongliang: '可領取總量',
        jiazhi: '價值',
        wodelingyang: '我的領養',
        tuanduijiangli: '團隊獎勵',
        lingqujiangli: '領取獎勵',
        wodeNFT: '我的NFT',
        kelingquNFT: '可領取NFT',
        lingqu: '領取',
        yilingquNftshulinag: '已領取nft數量',
        weiwandaixu: '未完待續',
    },

    mint: {
        hualuotuijianren: '滑落推薦人',
        zhituiyeji: '直推業績',
        wodezichan: '我的資產',

        wodeFC: '我的FC',
        kelingqujiangli: '可領取獎勵',
        jiazhi: '價值',

        wodesuanli: '我的算力',
        yilingqujiangli: '已領取獎勵',
        lingqu: '領取',

        wodelingyang: '我的領養',
        zongchanchu: '總產出',
        shengyuchanchu: '剩餘產出',

        
        wodezhitui: '我的直推'
    },

    nft: {

        wodeNFT: '我的NFT',
        kelingquNFT: '可領取NFT',
        yilingquNFT: '已領取nft數量',
        lingqu: '領取',

        NFTjiangli: 'NFT獎勵',
        lingqujiangli: '領取獎勵',
        
        tips: '註',
        tips1: 'NFT獎勵每週六9點09分開放領取',
        tips2: '未領取的獎勵自動轉入下期分紅池中',
    }
}