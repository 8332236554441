import Vue from 'vue'
import Vuex from 'vuex'
import { GetWalletAddress } from '@/assets/js/contract-fun'
import contractToken from '@/assets/js/contract'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    wallet: getWallet(),
    lang: getLang() || 'zh',
    loading: false,
    loadingText: '执行中',
    // bindAddress: contractToken.address,
    bindAddress: '',
    bindState: false,
    bindLoadingState: true,
    bindWindowState: false,
    bindAddressNFT: '',
    bindStateNFT: false,
    isFristLoad: true,
    approveState: false,
    language: getLang() || 'zh',
    currentChainId: '',
    userChainId: 56
    // userChainId: 97
  },
  mutations: {
    SET_language(state, lang) {
      setLang(lang)
      state.language = lang
    },
    SetIsFristLoad(state, type) {
      state.isFristLoad = type
    },
    SetLoading(state, type) {
      state.loading = type
    },
    SetLoadingText(state, type) {
      state.loadingText = type
    },
    SetWalletAddress(state, address) {
      // alert(`store-${address}`)

      const tempAddress = '0x0Ace0596c3c5e0F3C4D6F6180AaA15F4888bdAb5'
      setWallet(address)
      state.wallet = address
    },
    SetLang(state, lang) {
      setLang(lang)
      state.lang = lang
    },
    SetBindState(state, type) {
      state.bindState = type
    },
    SetBindAddress(state, address) {
      state.bindAddress = address || contractToken.address
    },
    SetBindWindowState(state, type) {
      state.bindWindowState = type
    },
    SetBindLoadingState(state, type) {
      state.bindLoadingState = type
    },
    SetBindStateNFT(state, type) {
      state.bindStateNFT = type
    },
    SetBindAddressNFT(state, address) {
      state.bindAddressNFT = address
    },
    
    SetApproveState(state, type) {
      state.approveState = type
    },
    SetCurrentChainId(state, id) {
      state.currentChainId = id
    },
    
  },
  actions: {
  },
  modules: {
  }
})

export function setWallet(data) {
  return localStorage.setItem("wallet", data);
}
export function getWallet() {
  return localStorage.getItem("wallet") || '';
}
export function getContractWallet() {
  const address = GetWalletAddress()
  return address;
}
export function getLang() {
  return localStorage.getItem("lang") || '';
}
export function setLang(lang) {
  return localStorage.setItem("lang", lang);
}