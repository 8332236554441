<template>
    <div class="connect-wallet font-color__theme border-color display-flex__align-center" style="visibility: hidden;">
        <span v-if="!wallet" @click="connectWallet">{{ $t('head.connect') }}</span>
        <span v-else @click="logout">
            {{ walletAddress }}
        </span>
    </div>
</template>

<script>
import { abi, GetWalletAddress } from "@/assets/js/contract-fun.js";
export default {
    name: 'connectWallet',
    data() {
        return {
            wallet: getWallet(),
            scoreAmount: ''
        }
    },
    computed: {
        walletAddress() {
            return this.wallet ? this.wallet.substr(0, 4) + "***" + this.wallet.substr(-4) : '连接钱包'
        }
    },
    watch: {
        "$store.state.wallet"(val) {
            this.wallet = val
        }
    },
    created() {
        if(!!!this.wallet)return
    },
    methods: {
        async connectWallet() {
            // const res = await window.ethereum.enable();
            localStorage.setItem('logout', 'false')
            const address = await GetWalletAddress()
            this.wallet = address
            this.$store.commit('SetWalletAddress', address)
            this.$emit('connectWallet', address)
        },
        logout() {
            this.$Dialog.confirm({
                title: '提示',
                message: '是否登出钱包?',
            }).then(() => {
                // on close
                this.wallet = ''
                localStorage.clear()
                this.$store.commit('SetWalletAddress', '')
                localStorage.setItem('logout', 'true')
                location.reload()
            }).catch(() => {
                // on cancel
            });
        }
    },
}
export function setWallet(data) {
  return localStorage.setItem("wallet", data);
}
export function getWallet() {
  return localStorage.getItem("wallet") || '';
}
</script>

<style lang="scss" scoped>
    .connect-wallet {
        text-align: center;
        font-size: 16px;
        // text-decoration: underline;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;

        border: 1px solid;
        border-radius: 4px;
        padding: 4px 6px;


        img {
            width: 24px;
            height: 24px;
            margin-right: 4px;
        }
    }
</style>