<template>
    <div class="CutInternet" :class="{'active': active}">
        
        <div class="CutInternet-box">
            
            <div class="image-box">
                <div class="image image-1">
                    <img :src="catWalkImage" alt="">
                </div>
                <div class="image image-2">
                    <img :src="catWalkImage" alt="">
                </div>
            </div>
<!-- 
            <div class="text-box">
                <div class="text text-1 font-color__theme-glod">
                    VCD ..
                </div>
                <div class="text text-2 font-color__theme-glod">
                    VCD ..
                </div>
            </div> -->
        </div>
    </div>
</template>

<style scoped lang="scss">
.CutInternet {
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(135deg, #1f3c63, #173052, #051122);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    overflow: hidden;
    transition: all .3s;

    &.active {
        transform: translateY(-120vh);
    }

    .CutInternet-box {
        width: 140px;
        height: 150px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        .text-box {
            position: relative;
            text-align: left;

            .text {
                font-size: 18px;
                white-space: nowrap;
                // text-align: center;
                margin-left: 50px;
            }
            .text-1 {
                overflow: hidden;
                animation: show 3s infinite;
            }
            .text-2 {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: .3;
            }
        }

        .image-box {
            position: relative;
            margin: 0 auto;
            width: 100px;

            .image {
                img {
                    width: 100px;
                    // height: 60px;
                    display: block;
                    margin: 0 auto;
                }
            }
            .image-1 {
                overflow: hidden;
                animation: show 3s infinite;
            }
            .image-2 {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: .3;
            }
        }
        
    }
}

@keyframes show {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
</style>
<script>

import { ethers } from 'ethers'
import { GetCurrentChainId } from '@/assets/js/contract-fun-newNft'

import catWalkImage from "@/assets/img/testpage/logo.png"

export default {
    data() {
        return {
            active: false,
            whiteList: [56, 97],
            catWalkImage
        }
    },
    computed: {
        currentChainId() {
            return this.$store.state.SetCurrentChainId
        },
        userChainId() {
            return this.$store.state.userChainId
        },
    },
    created() {
        console.log(this.currentChainId)
        // this.GetCurrentChainId()
    },
    mounted() {
        
        // this.chainChanged()
    },
    methods: {
        async GetCurrentChainId() {
            const currentChainIdData = await GetCurrentChainId() 
            console.log('currentChainIdData', currentChainIdData)
            this.$store.commit("SetCurrentChainId", currentChainIdData.chainId)

            if(this.currentChainId != this.userChainId) {
                this.cutInternet()
            }
        },
        async cutInternet() {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: ethers.utils.hexValue(this.userChainId) }],
            });
        },
        chainChanged() {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            window.ethereum.on('chainChanged', async (chainId) => {
                // 重新加载页面或执行其他逻辑来适应新网络
                console.log("网络已切换，新的 chainId:", chainId);

                // 获取新网络信息
                const network = await provider.getNetwork();
                console.log("切换到网络：", network.name);

                // 更新应用状态或重新连接资源
                // 这里可以添加您需要执行的代码来处理网络切换
            });
        },
        close() {
            this.active = true
        }
    }

}
</script>
