import Vue from 'vue'
import {
    GetWalletAddress,
    allowance,
    approve
} from './contract-fun'
import store from '@/store'
import { abi } from '@/assets/js/contract-fun-newNft'


const global = {
    version: 'v0.0.1',
    systemStop: false,
    testContentWallet: () => {
        console.log(GetWalletAddress());
    },

    // 渐入效果
    moduleShowAnimation: index => {
        return {
            opacity: 1,
            animation: `ComponentShow 1s`,
            'animation-fill-mode': 'both',
            'animation-delay': `${index * 0.03}s`
        };
    },

    // 验证 权限 并授权
    async testApprove() {
        const state = await allowance()
        return new Promise((resolve, reject) => {
            if (state) {
                store.commit('SetApproveState', true)
                resolve(true)
            } else {
                approve((data) => {
                    if (data) {

                        Vue.prototype.showSuccessMessageNotify('success', '授权成功!')
                        store.commit(`SetApproveState`, true)
                        resolve(true)
                    } else {
                        Vue.prototype.showSuccessMessageNotify('danger', '授权失败')
                        resolve(false)
                    }
                })
            }
        })
    },

    // 查询 推荐人
    async testBind(shareurl) {
        setTimeout( () => {

            const wallet = Vue.prototype.$getWallet()
            store.commit('SetBindLoadingState', true)
            abi({
                    abiName: "getRecommend",
                    type: "check",
                    params: [wallet],
                },
                async ({
                    code,
                    data,
                    message
                }) => {
                    if (!data || data == '0x0000000000000000000000000000000000000000') {
                        store.commit('SetBindState', false)
                        // store.commit('SetBindWindowState', true)
                        store.commit('SetBindAddress', shareurl)
                    } else {
                        store.commit('SetBindState', true)
                        store.commit('SetBindAddress', data)
                    }
                    
                    setTimeout(() => {
                        store.commit('SetBindLoadingState', false)
                    }, 1500);
                }
            );
        }, 500)
        
    },

    // 获取对应钱包里的余额
    GetWalletAmount(contractaddress, wallet) {
        return new Promise( (resolve, reject) => {
            Vue.prototype.$axios(`https://api.bscscan.com/api?module=account&action=tokenbalance&contractaddress=${contractaddress}&address=${wallet}&tag=latest&apikey=WEJDG4533ZH6RH4JU7KAZZSHTWZJUZXXAJ`,)
				.then( ({status, data}) => {
            if(status === 200) {
                if(data.status === '1') {
                    const amount = Math.floor(( Number(data.result) / 1000000000000000000 ) * 1000000000) / 1000000000
                    const filterAmount = Number(global.toolNumber(amount)).toFixed(2)
                    resolve(filterAmount)
                    console.log(filterAmount);
                }else {
                    global.GetWalletAmount()
                }
            }else {
                global.GetWalletAmount()
            }
        })
        })
        
    },

    // 处理18位
    SetFilterNumber18(number) {
        return number / 1000000000000000000
    },

    // 处理大数字
    toolNumber(num_str) {
        num_str = num_str.toString();
        if (num_str.indexOf("+") != -1) {
            num_str = num_str.replace("+", "");
        }
        if (num_str.indexOf("E") != -1 || num_str.indexOf("e") != -1) {
            var resValue = "",
                power = "",
                result = null,
                dotIndex = 0,
                resArr = [],
                sym = "";
            var numStr = num_str.toString();
            if (numStr[0] == "-") {
                // 如果为负数，转成正数处理，先去掉‘-’号，并保存‘-’.
                numStr = numStr.substr(1);
                sym = "-";
            }
            if (numStr.indexOf("E") != -1 || numStr.indexOf("e") != -1) {
                var regExp = new RegExp(
                    "^(((\\d+.?\\d+)|(\\d+))[Ee]{1}((-(\\d+))|(\\d+)))$",
                    "ig"
                );
                result = regExp.exec(numStr);
                if (result != null) {
                    resValue = result[2];
                    power = result[5];
                    result = null;
                }
                if (!resValue && !power) {
                    return false;
                }
                dotIndex = resValue.indexOf(".") == -1 ? 0 : resValue.indexOf(".");
                resValue = resValue.replace(".", "");
                resArr = resValue.split("");
                if (Number(power) >= 0) {
                    var subres = resValue.substr(dotIndex);
                    power = Number(power);
                    //幂数大于小数点后面的数字位数时，后面加0
                    for (var i = 0; i <= power - subres.length; i++) {
                        resArr.push("0");
                    }
                    if (power - subres.length < 0) {
                        resArr.splice(dotIndex + power, 0, ".");
                    }
                } else {
                    power = power.replace("-", "");
                    power = Number(power);
                    //幂数大于等于 小数点的index位置, 前面加0
                    for (var i = 0; i < power - dotIndex; i++) {
                        resArr.unshift("0");
                    }
                    var n = power - dotIndex >= 0 ? 1 : -(power - dotIndex);
                    resArr.splice(n, 0, ".");
                }
            }
            resValue = resArr.join("");

            return sym + resValue;
        } else {
            return num_str;
        }
    },

    walletAddressALittle(address, number, text) {
        if(!address) {
            return '-'
        }else if(address === '0x0000000000000000000000000000000000000000') {
            return '无'
        }else {
            return address ? address.substr(0, number) + "***" + address.substr(-(number)) : (text || '')
        }
        
    }
}

export default global;