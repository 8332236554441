<style lang="scss" scoped>
    .module-loading {
        width: 100%;
        height: 20vh;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .module-loading__icon {
        margin: 10px;
    }
    .module-loading__text {
        font-size: 20px;
        font-weight: 700;
        text-shadow: 0px 0px 5px rgba(0,0,0,.3);
    }
</style>
<template>
    <van-action-sheet v-model="loading" title="" :close-on-click-overlay="false">
      <div class="module-loading">
        <div class="module-loading__icon">
          <van-icon name="more" size="60" />
        </div>
        <div class="module-loading__text">
          {{loadingText}}..
        </div>
      </div>
    </van-action-sheet>
</template>

<script>
export default {
    name: 'module-loading',
    computed: {
        loading() {
            return this.$store.state.loading
        },
        loadingText() {
            return this.$store.state.loadingText
        },
    },
}
</script>